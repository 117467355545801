.nav {
  border-bottom: 1px solid var(--second-bg-color);
  transition: max-height 0.3s;
  max-height: 56px;
}

.navToggle {
  max-height: 375px;
  transition: max-height 0.3s;
}

.navTitle {
  font-size: 1.2em;
  color: #000000;
}

.navTitle a {
  font-size: 1em;
  color: #000000;
}

.navTitle span,
.navTitle i {
  font-size: 0.8em;
  align-self: center;
  margin-left: 1rem;
}

.navTitle i {
  color: var(--main-color);
}

.profile {
  background: var(--appMainDarkColor);
  background: linear-gradient(
    180deg,
    var(--appMainDarkColor) 0%,
    var(--main-color) 100%
  );
  color: var(--white-color) !important;
  text-align: center;
  line-height: 20px;
  font-size: 1.5em;
  width: 40px;
  height: 35px;
  border-radius: 7px;
}

.ProfileIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f8cbcb;
  background-image: linear-gradient(160deg, #f68484, #42427b);
  color: var(--white-color);
  font-size: 1em;
  font-weight: 600;
  line-height: 30px;
}

.notificationText {
  font-weight: 600;
}

.seeNotificationBtn {
  color: var(--main-color);
  font-weight: 600;
}

.ReportIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d3d1f3;
  background-image: linear-gradient(160deg, #6058dc, #302c6e);
  color: var(--white-color);
  font-size: 1em;
  font-weight: 500;
  line-height: 30px;
}

.profileBtn a li {
  margin-bottom: 10px;
}

.profileBtn li i {
  width: 30px;
  text-align: center;
}

.profileBtn a {
  color: #7a7a7a;
}

.profileBtn a:hover {
  color: var(--black-color);
}

.modalCloseBtn {
  color: var(--white-color);
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  padding: 6px;
  line-height: 14px;
  font-size: 0.75em;
  border: none;
  border-radius: 50%;
  background: #00000050;
}
.modalCloseBtn:hover {
  color: var(--white-color);
  background: #00000070;
}

// Loader Animation

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.text,
.image {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #e6e6e6;
  background: linear-gradient(to right, #f6f6f6 8%, #ffffff 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.image {
  width: 40px;
  height: 35px;
  border-radius: 7px;
}

.text {
  width: 230px;
  height: 39px;
}
