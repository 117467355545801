.formPickerPrimary {
  input {
    border: 1px solid var(--main-color);
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    background: transparent;

    &:hover,
    &:focus {
      border-color: var(--main-color);
    }
  }
}

.formPickerSecondary {
  input {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    background: var(--appMainLightColor);

    &:hover,
    &:focus {
      border: none;
    }
  }
}
