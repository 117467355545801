.floatLabel {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.5s ease all;
}

.labelFloat {
  top: -6px;
  left: 21px;
  padding: 0px 10px;
  font-size: 10px;
  background: white;
  border-left: 1px solid #1ea0c1;
  border-right: 1px solid #1ea0c1;
}
