.chatBox {
  height: 100%;
}

.chatBox.inbox {
  min-height: calc(100vh - 430px);
  max-height: calc(100ch - 720px);
  overflow: auto;
}

.chatBox p {
  margin-bottom: 10px;
  font-size: 12px;
  white-space: break-spaces;
}

.msgText {
  font-size: 12px;
}

.mmsImage {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.mmsImage img {
  width: 100px;
  height: auto;
}

.clientTextCover {
  background-color: #e1e1e140;
  width: fit-content;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  border-radius: 10px;
}

.userTextCover {
  background-color: #149cbe30;
  width: fit-content;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  border-radius: 10px;
}

.color {
  color: var(--main-color);
  color: #f8b195;
  color: #f67280;
  color: #e84a5f;
  color: #a8a7a7;
  color: #594f4f;
  color: #547980;
  color: #fc9d9a;
  // "#F8B195",
  // "#F67280",
  // "#E84A5F",
  // "#A8E6CE",
  // "#A8A7A7",
  // "#F7DB4F",
  // "#A7226E",
  // "#594F4F",
  // "#547980",
  color: #a7226e;
  color: #f68484;
  color: #f7db4f;
  color: #ecc679;
  color: #6058dc;
  color: var(--second-bg-color);
  color: #1caf92;
  color: #a8e6ce;
}

.removeUnicodeTextarea {
  background: transparent;
  border: none;
  width: 100%;
  white-space: pre-line;
  resize: none;
  height: calc(100vh - 500px);
  overflow: scroll;
}

.removeUnicodeTextarea:focus {
  outline: none !important;
}
