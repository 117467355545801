/* contactpast checkbox */
.past-contact-checkbox {
  position: relative;
}

.past-contact-checkbox i {
  position: absolute;
  top: 12px;
  font-size: 19px;
  left: 14px;
}

/* retail chat input */

.inputCollapse .ant-collapse-content-box {
  padding: 0 !important;
}

.inputCollapse .ant-collapse-header {
  padding: 0 !important;
  padding-left: 0px !important;
}

/* .radio-group-btn label,
.radio-group-btn:hover label,
.radio-group-btn:active label {
  border-color: var(--main-color) !important;
  color: var(--main-color) !important;
} */

.radio-group-btn label:last-child {
  border-left: 1px solid #d9d9d9;
}

.radio-group-btn label:last-child::before {
  content: none !important;
}

.gredient-text {
  position: relative;
  display: inline-block;
  color: transparent;
  background: -webkit-linear-gradient(16deg, #079bc0a1 0, #9b72cb 9%, #d96570 20%, #d96570 24%, #9b72cb 35%, #4285f4 44%, #9b72cb 50%, #d96570 56%, #9b72cb 75%, #24ccf7e6 100%);
  background: linear-gradient(74deg, #079bc0a1 0, #9b72cb 12%, #d96570 20%, #d96570 24%, #9b72cb 35%, #4285f4 44%, #9b72cb 50%, #d96570 56%, #9b72cb 75%, #24ccf7e6 100%);
  background-size: 400% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  animation: gradientMove 5s ease-in-out infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.spin {
  animation: 0.75s linear infinite round;
}

.loading-board {
  margin: 6rem 0px;
}

.loading-board img {
  width: 28px;
}

.loading-board > div:last-child {
  border-left: 2px solid #dee2e6;
  padding: 3.5rem 2rem 2rem 2rem;
  margin-left: 10px;
  margin-top: 14px;
  color: #778899;
  font-size: 13px;
}

.text-grey {
  color: rgb(124, 152, 182);
}

.contact-clipboard {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.contact-clipboard-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contact-clipboard-empty h5 {
  margin-top: 10px;
  color: #a0a0a0;
}

.contact-clipboard-empty h6 {
  color: #a0a0a0;
  font-weight: normal;
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contact-item {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.contact-remove-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
}

/* Existing styles */
.contact-clipboard {
  position: relative;
  height: 250px;
  padding: 3rem;
  border: 1px dashed #dee2e6;
  display: flex;
  margin: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: rgb(124, 152, 182);
  background-color: #f5f8fa;
  border-color: #516f90;
}

.contact-clipboard:hover,
.contact-clipboard:active,
.contact-clipboard:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: var(--main-color) !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Add any other styles you want to keep from the original CSS */

.contact-clipboard:hover,
.contact-clipboard:active,
.contact-clipboard:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: var(--main-color) !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.contact-clipboard img {
  margin: 35px 0px;
}

.contact-clipboard h5 {
  color: rgb(124, 152, 182);
}

.contact-clipboard h6 {
  font-size: 12px !important;
  color: rgb(124 152 182 / 88%);
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 770px) {
  .inputCollapse .ant-collapse-header {
    padding-left: 40px !important;
  }

  .loading-board > div:last-child {
    padding-top: 2rem !important;
  }
}

/* AntD Radio Button Styles */

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--main-color) !important;
  color: var(--main-color) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-left: 1px solid var(--main-color) !important;
}

.ant-radio-button-wrapper:hover {
  color: var(--main-color) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--main-color) !important;
  border-width: 6px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--main-color) !important;
  border-width: 6px !important;
}

.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  border-color: #707070 !important;
}

.ant-radio-inner::after {
  background-color: transparent !important;
}

.ant-typography-expand {
  color: var(--main-color) !important;
}

/* .ant-radio-wrapper span {
  color: #67726c;
} */

/* AntD Select Input Styles */

/* .ant-select-disabled .ant-select-selection.ant-select-selection--single {
  border: 1px solid #212529 !important;
} */
.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* AntD Table Styles */
form input[type="checkbox"],
form input[type="radio"] {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox-inner {
  border-radius: 50% !important;
  border: 3px solid var(--main-color) !important;
  height: 20px !important;
  width: 20px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--main-color) !important;
}

.ant-table-thead > tr > th {
  background-color: var(--white-color) !important;
  font-weight: 700 !important;
}

.ant-table-header.ant-table-hide-scrollbar {
  margin-right: -9px;
}

.ant-table table {
  font-size: 12px;
}

/* .ant-table table {
  border-spacing: 0 10px; 
} */

.ant-table-footer {
  background-color: var(--white-color) !important;
  border-top: var(--main-color) solid 2px !important;
  font-size: 1.1em;
}

.ant-table-row-selected td {
  color: var(--main-color) !important;
}

.ant-table-row-selected td span {
  color: var(--main-color) !important;
}

.ant-table-row-selected td span.ant-switch-inner {
  color: white !important;
  min-width: 20px;
}

.ant-table-row td span.ant-switch-inner {
  color: white !important;
  min-width: 20px;
}

.ant-pagination.ant-table-pagination {
  margin: 0.5rem !important;
}

.tableBtn {
  color: var(--main-color) !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  background-color: transparent;
  border: none;
}

.custom-table .ant-table-row td {
  /* background-color: #11b6ca10 !important; */
  border: none !important;
}

/* .custom-table .ant-table-row td:first-child {
  border-radius: 10px 0 0 10px;
}

.custom-table .ant-table-row td:last-child {
  border-radius: 0 10px 10px 0;
} */

/* .custom-table .ant-table-body table {
  border-spacing: 0 5px !important;
} */

.custom-table .ant-checkbox-inner {
  border: 3px solid var(--appMainDarkColor) !important;
}

.custom-table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--appMainDarkColor) !important;
}

.custom-table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--appMainDarkColor) !important;
}

/* AntD Menu Styles */
.ant-menu-item-selected {
  background-color: var(--white-color) !important;
}

.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}

/* AntD Upload */

.ant-upload.ant-upload-select {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.ant-upload.ant-upload-select:hover {
  border: 1px dashed var(--main-color);
}

/* to remove the border & shadows from upload btn */
.ant-upload-rm-border-shadow > .ant-upload.ant-upload-select {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-upload-rm-border-shadow > .ant-upload.ant-upload-select:hover {
  border: 1px dashed var(--main-color) !important;
}

/* AntD Steps */
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 18px;
  background-color: var(--main-color) !important;
  border-top: var(--white-color) 8px solid;
  border-bottom: var(--white-color) 8px solid;
  box-shadow: #00000050 0px 2px 8px -4px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 18px;
  border-top: var(--white-color) 8px solid;
  border-bottom: var(--white-color) 8px solid;
  box-shadow: #00000050 0px 2px 8px -4px;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 18px;
  border-top: var(--white-color) 8px solid;
  border-bottom: var(--white-color) 8px solid;
  box-shadow: #00000050 0px 2px 8px -4px;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-top: 26px;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 0px !important;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 95px !important;
}

.ant-steps-item-title {
  font-size: 12px !important;
  line-height: 16px !important;
}

.ant-steps-item-title:has(span) {
  margin-top: 0.5rem !important;
}

.ant-steps-item-title:has(span):after {
  top: 8px !important;
}

/* AntD Modal */

.ant-modal-header {
  border-bottom: none !important;
}

/* AntD Tooltip */
.ant-tooltip-arrow::before {
  background: #ffffff !important;
}

.ant-popover-title,
.ant-tooltip-inner {
  text-transform: capitalize;
  background: #ffffff !important;
  color: var(--main-color) !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

/* AntD Popover */

.popover-custom .ant-popover-inner {
  border-radius: 13px !important;
}

.popover-custom .ant-popover-inner-content {
  padding: 10px 10px 1px !important;
}

.popover-custom .ant-popover-inner-content li:hover {
  background: #f1faf8;
  border-radius: 5px;
  color: var(--main-font-color) !important;
}

/* Bootstrap */

.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover,
.navbar-light .navbar-toggler:visited {
  outline: none !important;
  box-shadow: none !important;
  box-shadow: none !important;
}

.navbar-light .navbar-toggler {
  border: none !important;
}

/* AntD Input TextArea */

.ant-form-item-label > label.ant-form-item-no-colon:after {
  display: none !important;
}

.ant-form-item-no-colon {
  text-align: start !important;
}

/* AntD Input Password */
.ant-input-password .ant-input.ant-input-lg {
  padding-left: 1rem !important;
}

/* Mobile Design start */
/* AntD Tabs */

.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--main-color) !important;
}

.ant-tabs-ink-bar {
  background-color: var(--main-color) !important;
}

.ant-tabs-tab {
  text-align: center;
}

.ant-tabs-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  display: none;
}

.ant-tabs-nav-scroll {
  overflow: scroll !important;
}

.ant-tabs-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-tabs-nav.ant-tabs-nav-animated {
  transform: translate3d(0, 0, 0) !important;
}

.ant-tabs-tab-next-icon-target,
.ant-tabs-tab-prev-icon-target {
  font-size: large !important;
  color: var(--main-font-color);
}

/* Float Button Mobile View */
.rightBottombtn i {
  font-size: 35px;
  background: var(--main-color);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.rightBottombtn i.active {
  transform: rotate(135deg);
  transition: transform 0.2s ease-in-out;
}

.rightBottombtn {
  position: fixed;
  left: 15px;
  bottom: 15px;
  z-index: 9999;
}

.ant-modal.rightbottomsmsquick {
  bottom: 50px !important;
  top: unset;
  position: absolute;
  right: 0;
  text-align: left;
}

.rightbottomsmsquick .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.rightbottomsmsquick .ant-modal-content span {
  background-color: #dedede;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #000;
}

.rightbottomsmsquick .ant-modal-content img {
  background-color: #dedede;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 5px;
}

/* Mobile Design closed */

/* Phone Input */
.ant-form-item-children .special-label {
  display: none !important;
}

/* AntD Switch */
.ant-switch-checked {
  background-color: var(--main-color) !important;
}

/* AntD Card */
.ant-card-extra {
  padding-bottom: 0 !important;
}

.ant-card-meta-description {
  width: 100% !important;
  display: inline-flex !important;
  justify-content: space-between !important;
}

/* Adnt Modal Methords */
.ant-modal-confirm-title,
.ant-modal-confirm-content {
  text-align: center !important;
}

.ant-modal-confirm-btns {
  width: 100% !important;
  text-align: center !important;
}

.ant-modal-confirm-body-wrapper .ant-btn {
  border: none !important;
  color: #fff !important;
  background-color: var(--main-color) !important;
}

.ant-list-item-meta-avatar {
  margin: auto !important;
}

/* timepicker panel */

.ant-time-picker-panel-select {
  height: 150px !important;
}

/* rangepicker */
.ant-calendar-picker-input.ant-input {
  border: 1px solid var(--main-color) !important;
  border-radius: 4px !important;
  width: 100% !important;
  background: transparent !important;
  display: flex;
}

.ant-calendar-picker-input.ant-input input {
  border: none !important;
}

.campaign-not-schedule .ant-calendar-picker-input.ant-input {
  border: 1px solid #d9d9d9 !important;
}

/* Tabs */
/* Campaign */

.campaign-tabs .ant-tabs-bar.ant-tabs-top-bar {
  margin: 0 !important;
  border: none !important;
}

.campaign-tabs .ant-tabs-nav-scroll {
  overflow: hidden !important;
}

.campaign-tabs .ant-tabs-tab-active.ant-tabs-tab {
  background-color: #fff !important;
  border-radius: 10px 10px 0 0 !important;
}

.campaign-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  /* display: none !important; */
  background-color: var(--main-color) !important;
}

.campaign-radio .ant-radio-button-wrapper:not(:first-child):before {
  display: none !important;
}

.campaign-radio .ant-radio-button-wrapper {
  height: 100% !important;
}

.campaign-radio .dollar-sign {
  bottom: -25px;
  right: -10px;
  text-align: center;
  width: 30px;
  border-radius: 10px 0 10px 0;
}

.campaign-radio .senders-radio .ant-radio-inner {
  opacity: 1 !important;
}

.campaign-checkbox .ant-checkbox {
  display: none !important;
}

.buy-switch .ant-switch-checked:after,
.buy-switch .ant-switch:after {
  background-color: #1cb092 !important;
  height: 28px;
  width: 43px;
  top: 2px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "gothambold";
  text-transform: uppercase;
  color: #fff;
}

.buy-switch .ant-switch:after {
  content: "SMS";
  left: 2px;
}

.buy-switch .ant-switch-checked:after {
  content: "MMS";
  right: 2px;
  left: 98%;
}

.buy-switch .ant-switch-inner {
  color: #000 !important;
  font-family: "gothambold";
  font-size: 15px;
  margin-left: 50px;
}

.buy-switch .ant-switch-checked .ant-switch-inner {
  margin-right: 50px !important;
  margin-left: 6px;
}

.buy-switch .ant-switch-checked,
.buy-switch .ant-switch {
  background-color: #fff !important;
  border: 1px solid #1cb092 !important;
  height: 35px;
  width: 100px;
}

.buy-slider .ant-slider-track {
  background-color: #0061ff !important;
  height: 12px;
  transform: translateY(-4px);
}

.buy-slider .ant-slider-handle,
.buy-slider .ant-slider-handle:focus,
.buy-slider .ant-slider-handle:hover,
.buy-slider .ant-slider-handle:active {
  top: 50%;
  transform: translate(-50%, -50%) !important;
  width: 75px;
  height: 74px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  background-color: #0061ff;
  border: 5px solid #fff !important;
}

.buy-slider .slider-amount {
  color: #fff !important;
  font-family: "gothambold";
}

.buy-credit-input .ant-input:focus {
  box-shadow: none !important;
}

/* Table expand btn */

.ant-table-row-expand-icon {
  border-radius: 50% !important;
  border: 1px solid var(--main-color) !important;
}

.ant-table-row-collapsed:after,
.ant-table-row-expanded:after {
  line-height: 1.3;
  font-weight: bold;
  color: var(--main-color);
}

.workflow-action-popover .ant-popover-inner-content {
  padding: 0 !important;
}

/* .react-flow__attribution {
  display: none !important;
} */

.ant-tooltip-inner {
  text-transform: math-auto !important;
  font-size: 12px !important;
  min-height: 24px !important;
  padding: 4px 6px !important;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: start !important;
  text-transform: capitalize !important;
  margin: 0 10px 16px 0 !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu-title-selected > a {
  color: var(--main-color) !important;
}

.img-fit {
  object-fit: contain;
}

/* progressive progress bar */

.progressLine {
  width: 100px;
  height: 5px;
  background-color: #4caf504d;
  display: inline-block;
  position: relative;
}

.progressPoint {
  width: 25px;
  height: 5px;
  background: linear-gradient(to right, rgba(76, 175, 80, 0), rgba(76, 175, 80, 0.4), rgba(76, 175, 80, 0.8));
  position: absolute;
  top: 0;
  left: 6px;
  animation: 1.4s cubic-bezier(0.71, 0.22, 0.33, 0.8) infinite circleAnimMove;
}

.progressPoint:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #12aa67;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;
  margin: auto;
  border-radius: 30px;
}

@keyframes circleAnimMove {
  0% {
    left: -12%;
  }

  100% {
    left: 66%;
  }
}
.group-search-container .group-search-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 16px;
}

.group-search-container .group-search-content {
  display: flex;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
  margin-right: 16px;
}

.group-search-container .group-avatar {
  margin-right: 16px;
  flex-shrink: 0;
}

.group-search-container .group-info {
  flex: 1;
  min-width: 0;
  max-width: calc(100% - 120px);
}

.group-search-container .group-name {
  font-size: 16px;
  margin-bottom: 4px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; /* Allows the text to wrap to the next line */
  word-break: break-word; /* Ensures long words are wrapped */
}

.group-search-container .contact-info .ant-typography span {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; /* Allows text to wrap to the next line */
  word-break: break-word; /* Wraps long words to prevent overflow */
}

.group-search-container .contact-info {
  display: flex;
  flex-direction: column;
}

.group-search-container .contact-info .ant-typography {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2px;
}

.group-search-container .contact-info .anticon {
  margin-right: 8px;
  flex-shrink: 0;
}

.group-search-container .viyew-group-link {
  flex-shrink: 0;
  width: 100px;
  align-self: center;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--main-color) !important; /* Add !important to override default */
}
.schedule-active-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #52c41a;
  border-radius: 50%;
  position: relative;
}

.schedule-active-dot::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: schedulePulse 2s infinite;
}

@keyframes schedulePulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.stat-card {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  min-height: 120px;
  min-width: 180px;
  margin-left: 10rem;
}

.occurrences-table {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f0f0f0;
}

.occurrences-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 600;
  padding: 12px 16px;
  border-bottom: 2px solid #f0f0f0;
}

.occurrences-table .ant-table-tbody > tr > td {
  padding: 12px 16px;
  transition: background 0.3s;
}

.occurrences-table .ant-table-tbody > tr:hover > td {
  background-color: #f5f5f5;
}

.occurrences-table .ant-table-tbody > tr > td:first-child {
  font-weight: 500;
}

.occurrences-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.occurrences-table .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #f5f5f5;
}

.occurrences-table .ant-table-body::-webkit-scrollbar {
  width: 6px;
}

.occurrences-table .ant-table-body::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 3px;
}

.occurrences-table .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
}

.occurrences-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.occurrences-table .ant-empty {
  padding: 24px 0;
}

/* .form-input-fixed-width {
  width: 31rem !important;
  max-width: 31rem !important;
  min-width: 31rem !important;
} */
.form-input-fixed-width {
  width: 31rem !important;
  max-width: 31rem !important;
  min-width: 31rem !important;
}

/* For screens smaller than 768px */
@media (max-width: 767.98px) {
  .form-input-fixed-width {
    width: 23.2rem !important;
    max-width: 23.2rem !important;
    min-width: 23.2rem !important;
  }
}
