.spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 50px;
}

.item {
  background-color: var(--main-color);
  height: 100%;
  width: 12px;
  border-radius: 6px;
  animation: spinner-animation 2s ease-in-out infinite;
}

.item-1 {
  animation-delay: -1.1s;
}

.item-2 {
  animation-delay: -1s;
}

.item-3 {
  animation-delay: -0.9s;
}

.item-4 {
  animation-delay: -0.8s;
}

.item-5 {
  animation-delay: -0.7s;
}

.item-6 {
  animation-delay: -0.6s;
}

.item-7 {
  animation-delay: -0.5s;
}

.item-8 {
  animation-delay: -0.4s;
}

.item-9 {
  animation-delay: -0.3s;
}

.item-10 {
  animation-delay: -0.2s;
}

@keyframes spinner-animation {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    opacity: 0.4;
  }
  20% {
    transform: scaleY(1);
    opacity: 1;
  }
}
