@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.card {
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  background-color: #fbfbfb;
  border-radius: 7px;
  box-shadow: 2px 1px 7px 0px rgb(0 0 0 / 12%);
  font-family: "Poppins", sans-serif;
}

.card:hover {
  border: 2px solid rgb(20 156 190 / 65%);
  padding: 16px;
  box-shadow: 1px 1px 7px 0px rgb(20 156 190 / 55%);
}

.userCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  font-family: "Poppins", sans-serif;
}

.childBorder > div {
  border: 1px solid #e9ebef;
  border-right: 0;
  flex: 1 1 20%;
}

.childBorder > div:last-child {
  border: 1px solid #e9ebef;
}

.childVerticalBorder > div {
  border: 1px solid #e9ebef;
  border-bottom: 0;
  flex: 1;
}

.childVerticalBorder > div:last-child {
  border: 1px solid #e9ebef;
}

.tabTopbar > div[role="tablist"] {
  padding: 2px 30px;
  background-color: white;
  margin: 0px !important;
  border-radius: 3px;
}

.sideCard {
  height: 6rem;
}

.sideCard .footer {
  font-size: 11px;
}

.split {
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  font-weight: bold;
}

.split > hr {
  margin: 0px;
}

.split > div > span {
  font-size: 10px;
  margin-left: 5px;
}

.content {
  font-size: 1.4rem;
  display: flex;
}

.footer {
  font-size: 0.9rem;
  font-weight: 600;
}

.createButton:hover,
.createButton:focus,
.createButton:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-radius: 10px !important;
}

.prefix {
  margin-right: 2px;
  font-size: 1.1rem;
  align-self: center;
}

.shortcutBtn {
  border: 1px solid #134f5f99 !important;
}

.shortcutBtn:hover {
  box-shadow: 0 0.1rem 4px 2px rgb(0 0 0 / 23%) !important;
}
