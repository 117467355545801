.card {
  border-top: 2px solid var(--main-color);
  max-height: fit-content;
  transition: max-height 0.3s ease-in-out;
}

.card.active {
  max-height: fit-content;
  transition: max-height 0.3s ease-in-out;
}

.content {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
